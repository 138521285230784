/* ----------------SMOOTH SCROLL---------------- */

html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* COLOR VARIABLES */

/* color palette taken from https://www.awwwards.com/trendy-web-color-palettes-and-material-design-color-schemes-tools.html */

:root {
  --primary-blue-color: #3d6d79;
  --primary-orange-color: #dd9221;
  --light-orange-color: #EDB766;
  /* --primary-orange-color: #d69d66; */
  /* --primary-orange-color: #ff9100; */
  --primary-grey-color: #e0e2ee;
  --primary-white-color: #fff;
}

.text-white {
  color: var(--primary-white-color);
}

.text-orange {
  color: var(--primary-orange-color);
}

.bg-blue {
  background-color: var(--primary-blue-color);
}

.bg-orange {
  background-color: var(--primary-orange-color);
}

.bg-grey {
  background-color: var(--primary-grey-color);
}

.bg-white {
  background-color: var(--primary-white-color);
}

/* GLOBAL FONTS */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Open+Sans&family=Righteous&display=swap');
body {
  font-family: 'Roboto Mono', monospace;
}

body {
  height: 100%;
  background-color: var(--primary-grey-color);
}

.overflow-hidden {
  overflow: hidden;
}

#root {
  background-color: var(--primary-blue-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loader-wrapper {
  z-index: 50;
}

.initial-transition {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* border-top: 3px solid red; */
  padding-top: 55px;
}

.initial-transition>.blue-box {
  position: relative;
  z-index: 50;
}

.scrollbar {
  width: 100%;
  height: 100%;
}

.loading-bar {
  position: absolute;
  top: 0;
  height: 54px;
  z-index: 100;
  border-bottom: 3px solid var(--primary-orange-color);
}

.h-100-vh {
  height: 100vh;
}

/* NAVBAR */

.navbar {
  border-bottom: 3px solid var(--primary-orange-color);
}
.nav-item {
  border-color: black !important;
}

.nav-link {
  font-family: 'Open Sans', sans-serif;
  font-size: .8em;
  font-weight: 600;
  letter-spacing: .2em;
  border-radius: .25rem;
}

.nav-link, .nav-link:active, .nav-link:visited {
  color: black;
  border-color: black;
}

.nav-link.active {
  background-color: var(--primary-orange-color);
}

.nav-link:hover {
  color: var(--primary-white-color);
  background-color: var(--primary-blue-color);
}


/* ABOUT */
/* 
.react-icons {
  border: white solid 3px;
  border-radius: 50%;
} */

.profile-picture img {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  border: white 3px solid;
  padding: 6px;
  object-fit: cover;
}

.circle-icon {
  width: 3em;
  height: 3em;
  border: white 3px solid;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.profile-link {
  text-decoration: underline;
  transition-duration: 0.2s;
}

.profile-link:hover {
  color: var(--primary-orange-color) !important;
  border-color: var(--primary-orange-color) !important;
}

.profile-link .circle-icon {
  border-color: inherit;
  color: inherit;
}

.skills-icon {
  width: 6em;
}

.skills-icon .circle-icon {
  padding: 0.6em;
}

/* PROJECTS */

.card {
  background-color: var(--primary-grey-color);
}

.card-body .btn:hover {
  background-color: var(--light-orange-color);
}

.card-img-top {
  object-fit: cover;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.card-body .skills-icon {
  width: 6.8em;
}

.card-body .skills-icon .circle-icon {
  padding: 0.1em;
  width: 2em;
  height: 2em;
}


/* FORM */

@media screen and (max-width: 360px) {
  .recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

a.text-orange:hover {
  color: var(--light-orange-color);
}

.form-control:focus {
  border-color: var(--primary-orange-color);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(221,146,33,.25);
          box-shadow: 0 0 0 0.2rem rgba(221,146,33,.25);
}